import * as React from "react";
import { format } from "date-fns";
import toast from "react-hot-toast";
import clsx from "clsx";

import * as api from "../../api/proxies";
import { Proxy, ProxyLog } from "../../types";
import { useAuthContext } from "../../context/AuthContext";
import { useEnvironmentContext } from "../../context/EnvironmentContext";

export default function Logs({
  proxy,
  query,
}: {
  proxy?: Proxy;
  query?: string;
}) {
  const { token } = useAuthContext();
  const { environment } = useEnvironmentContext();

  const [logs, setLogs] = React.useState<string | undefined>(undefined);

  const load = async () => {
    if (!proxy || !token) {
      return;
    }

    try {
      const res = await api.getProxyLogs(
        proxy.id.toString(),
        environment,
        token
      );
      setLogs(res);
    } catch (ex: any) {
      console.log(ex.message);
      toast.error(ex.message, { position: "bottom-right" });
    }
  };

  React.useEffect(() => {
    load();
  }, [proxy?.id]);

  const formattedLogs = logs
    ?.split("\n")
    .filter((line: string) => {
      try {
        JSON.parse(line); // Check if the line is valid JSON
        return true;      // Keep the line if it's valid JSON
      } catch (e) {
        return false;     // Exclude the line if it's not valid JSON
      }
    })
    .map((line) => {
      // Here you need to extract the relevant properties from the parsed JSON line
      const parsedLine = JSON.parse(line); // Parse the valid JSON line
      
      return {
        timestamp: parsedLine.asctime?.replace(',', '.'), // Adjust based on actual JSON structure
        type: parsedLine.levelname || 'unknown',          // Use a default value if type is not present
        lineNumber: parsedLine.lineno || 'unknown',       // Use a default value if line number is not present
        content: parsedLine.message || '',                // Use an empty string if content is not present
      };
    });


  return (
    <div className="p-2 rounded whitespace-nowrap overflow-auto w-full h-[40vh]">
      {formattedLogs && (
        <table className="table-auto border-separate text-right border-spacing-x-2">
          <thead><tr><td></td><td></td><td></td><td></td></tr></thead>
          <tbody>
          {formattedLogs.length > 0 &&
            formattedLogs.map((log: ProxyLog, i: number) => {
              return (
                <tr
                  className="text-white text-sm font-mono gap-1"
                  key={i}
                >
                  <td className="">
                    {log.timestamp && format(new Date(log.timestamp), "dd/MM/yyyy HH:mm:ss")}
                  </td>
                  {(log.lineNumber) ? <>
                    <td className="text-white/50">[{log.lineNumber}]</td>
                    <td
                      className={clsx("", {
                        "text-blue-400/70": log.type === "INFO",
                        "text-purple-400/70": log.type === "DEBUG",
                        "text-red-400/70":
                        log.type === "ERROR" || log.type === "CRITICAL",
                      })}
                      >
                      {log.type}
                    </td>
                  </> : <></>}
                  <td className="text-white text-left">{log.content}</td>
                </tr>
              );
            })}
          {formattedLogs.length === 0 && (
            <div className="flex items-center justify-center">
              <span className="text-sm text-white/40">No logs found333</span>
            </div>
          )}
        </tbody></table>
      )}
    </div>
  );
}
